/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
@import '../../scss/utils/bootstrap.scss';

@import '~react-toastify/scss/variables';
@import '~react-toastify/scss/toastContainer';
@import '~react-toastify/scss/toast';
@import '~react-toastify/scss/closeButton';
@import '~react-toastify/scss/progressBar';

// entrance and exit animations
@import '~react-toastify/scss/animations/bounce.scss';
@import '~react-toastify/scss/animations/zoom.scss';
@import '~react-toastify/scss/animations/flip.scss';
@import '~react-toastify/scss/animations/slide.scss';
$rt-color-info: $blue !default;
$rt-color-success: $green !default;

#wr_custom_notify {
  background-color: $color-blue-7;
  padding-left: 15px !important;
  padding-right: 15px !important;

  a {
    text-decoration: underline !important;
    color: $white !important;
  }
}
.Toastify__close-button {
  position: absolute;
  right: 20px;
  top: 20px;
}
.Toastify__close-button > svg {
  width: 24px !important;
  height: 24px !important;
}
.text-green > .Toastify__close-button {
  color: $green !important;
}
.text-yellow-200 > .Toastify__close-button {
  color: $yellow-200 !important;
}
.text-red-100 > .Toastify__close-button {
  color: $red-100 !important;
}
.Toastify__toast-container {
  min-width: 509px;

  .Toastify__toast-theme--light {
    position: relative;
    min-height: 90px !important;
    border-radius: 6px;
    // padding: 12px 10px 12px 20px;
    &.Toastify__toast--success {
      background-color: #d1fae5;
    }
    &.Toastify__toast--error {
      background-color: #fee2e2;
    }
    &.Toastify__toast--warning {
      background-color: #fff8e9;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(../../../public/assets/images/toast_bg.svg);
      background-repeat: repeat;
      opacity: 0.19;
      z-index: 0;
    }
    .Toastify__toast-body {
      padding: 0;
      margin: 0;
      display: flex !important;
      align-items: center !important;
      flex-wrap: wrap !important;
      .Toastify__toast-icon {
        width: auto;
        height: 48px;
        margin-inline-end: 16px;
      }
    }
  }
}
