/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

@import './utils/bootstrap.scss';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';

@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/nav';

@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

@import './utils/atomic.scss';

@import '../fonts/fontface.scss';

@import '../themes/index.scss';

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: $gray-500;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 5px;
  background-color: $gray-500;
  box-shadow: inset 0 0 6px transparent;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: #fff;
  box-shadow: inset 0 0 6px transparent;
}
