@import '../../scss/utils/bootstrap.scss';

.wr_input_search {
  width: 656px !important;
}

.wrapper_dropdown {
  .dropdown-menu {
    &.show {
      min-width: 400px;
    }
  }
}

.wrapper_avatar {
  .dropdown-menu {
    &.show {
      min-width: 230px;
    }
  }
}

.item_collap {
  width: 24px;
  height: 24px;
  left: -12px;

  svg {
    transition: all 0.4s ease;
  }
}

// MINI SBAR LEFT

.mini_left {
  // .wrapper_header_logo {
  //   background-color: $gray-900;
  // }

  .w-248 {
    width: 125px;
  }
  .header_logo {
    color: $white;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .item_collap {
    svg {
      transform: rotateY(180deg);
    }
  }

  .item_menu {
    &.item_menu_home {
      padding: 0 !important;
    }

    .link_menu {
      text-align: center;
      position: relative;
      // &.active,
      // &:hover {
      //   background-color: $red;
      // }
      .text {
        visibility: hidden;
        display: none !important;
      }
    }
    .img_menu_color {
      display: inline-block !important;
      visibility: visible;
      margin-bottom: 0 !important;
    }
    .img_menu {
      display: none !important;
    }
    .icon-submenu{
      display: block;
    }
  }

  .sidebar {
    background-color: $gray-900 !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .item_upgrade {
    display: none;
  }

  .link_upgrade {
    span {
      display: none;
    }
  }

  .wr_list_menu_2 {
    padding: 0 !important;
  }

  .section_menu_title {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 11px;
    color: $white;
  }
}

.all_project {
  .dropdown {
    display: none;
  }
}

.all_header {
  .all_project {
    .dropdown {
      display: block;
    }
  }
}

@include media-breakpoint-down(xl) {
  .sidebar {
    position: fixed !important;
    left: -260px;
    top: 0;
    bottom: 0;
  }

  .sdbar_right {
    right: -100% !important;
    z-index: 50;
  }

  .wrapper_header_logo {
    width: auto;
  }

  .content_header {
    border-left: 0 !important;

    .item_collap {
      display: none !important;
    }
  }

  .wr_input_search {
    width: 300px !important;
  }
  .main-layout {
    transition: 0.5s all ease-in-out;
  }

  .show_menu_left {
    transform: translateX(260px);
    transition: 0.5s all ease-in-out;
    .wrapper_header {
      .item_hambuger {
        &:first-child {
          transform: rotate(-45deg) translate(-5px, 5px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: rotate(45deg) translate(-5px, -5px);
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .wrapper_avatar {
    .text,
    .icons {
      display: none;
    }
  }

  .wr_help_center {
    .text {
      display: none;
    }
  }
}
