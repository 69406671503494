.dark {
  $white: #242d31;
  $gray-100: #f6f8fa;
  $gray-200: #e9ecef;
  $gray-300: #495057;
  $gray-400: #495057;
  $gray-500: #babad0;
  $gray-600: #6c757d;
  $gray-700: #3e545e;
  $gray-800: #949494;
  $gray-900: #1a2226;
  $black: #fff;
  // fusv-disable
  $grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  );
  // fusv-enable

  $blue: #3b7ab5;
  $indigo: #6610f2;
  $purple: #6f42c1;
  $pink: #d63384;
  $red: #cb222c;
  $orange: #f78464;
  $yellow: #ff9e01;
  $green: #1ab394;
  $teal: #20c997;
  $cyan: #1a73e8;

  // scss-docs-start colors-map
  $colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': #fff,
    'gray': $gray-600,
    'gray-dark': $gray-800,
    'gray-900': #f6f8fa,
    'danger': $red,
  );

  // scss-docs-end colors-map
  $primary: $blue;
  $secondary: $gray-600;
  $success: $green;
  $info: $white;
  $warning: $yellow;
  $danger: $red;
  $light: $gray-100;
  $dark: $gray-900;

  // scss-docs-end theme-colors-map
  $theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'gray-100': $gray-100,
    'gray-300': $gray-300,
    'cyan': $cyan,
    'theme': #0c1215,
    'gray-200': $gray-200,
    'gray-400': $gray-400,
    'white': #000,
  );

  $body-color: #fff;
  $body-bg: #1a2226;
  color: $body-color;
  background-color: $body-bg;

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  $link-color: $primary;
  $link-hover-color: shift-color($link-color, $link-shade-percentage);

  $box-shadow: 0 3px 6px rgba($black, 0.15);
  $box-shadow-sm: 0 0.185rem 0.3125rem rgba($black, 0.05);
  $box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
  $box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);
  $box-shadow-pagination: 0px 0px 0px 1px #3E545E;

  //COLOR
  $color: #3c3c3c;

  //MAIN COLOR RED
  $color-red: #c60202;
  $color-red-2: #ef373780;
  $color-red-3: #d30011;
  $color-red-4: #ff2366;

  $color-pink: #8d25a8;

  $color-green-2: #7ce7ac75;
  $color-green-3: #39b67a;

  $color-orange: #f24f29;

  $color-gray: #616161;
  $color-gray-2: #f2f2f2;
  $color-gray-3: #e9ebee;

  $color-purple: #4052b3;

  //MAIN COLOR BLUE
  $color-blue: #fff;
  $color-blue-1: #3b5998;
  $color-blue-2: #00acee;
  $color-blue-3: #3b7ab5;
  $color-blue-4: #0f1414;
  $color-blue-5: #e8f1f6;
  $color-blue-6: #282831;
  $color-blue-7: #199ce3;
  $color-blue-8: #54c7ec;

  //BORDER COLOR
  $border-color: #3e545e;

  $accordion-button-bg: $color-blue-5;
  $accordion-button-active-bg: $color-blue-6;
  $accordion-border-color: $border-color;
  $accordion-button-focus-border-color: $border-color;
  $accordion-button-focus-box-shadow: $color-blue-6;
  $nav-tabs-link-active-color: #fff;
  $nav-tabs-link-active-bg: $color-blue-3;

  $table-striped-bg: #161c1f;
  $table-accent-bg: #242d31;

  $input-color: $body-color !important;
  $input-placeholder-color: $body-color;
  $input-border-color: $border-color;
  $form-check-input-border: 1px solid #3e545e;
  // maps

  $nav-link-color: $gray-800;
  $nav-tabs-link-active-color: $color-blue;
  $nav-link-hover-color: $gray-800;
  $nav-tabs-border-color: $border-color;

  $dropdown-color: $body-color;
  $dropdown-bg: $body-bg;

  $modal-content-bg: #242d31;

  --#{$prefix}border-color: #{$border-color};
  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  $theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  /* end of redefine theme color variables */

  // scss-doc-end dropdown variable

  // scss-docs-start btn-variant-loops
  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      @if $color == 'light' {
        @include button-variant(
          $value,
          $value,
          $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
          $hover-border: shade-color($value, $btn-hover-border-shade-amount),
          $active-background: shade-color($value, $btn-active-bg-shade-amount),
          $active-border: shade-color($value, $btn-active-border-shade-amount)
        );
      } @else if $color == 'dark' {
        @include button-variant(
          $value,
          $value,
          $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
          $hover-border: tint-color($value, $btn-hover-border-tint-amount),
          $active-background: tint-color($value, $btn-active-bg-tint-amount),
          $active-border: tint-color($value, $btn-active-border-tint-amount)
        );
      } @else {
        @include button-variant($value, $value);
      }
    }
  }

  @each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }
  // scss-docs-end btn-variant-loops

  // scss-docs-start alert-modifiers
  // Generate contextual modifier classes for colorizing the alert.

  @each $state, $value in $theme-colors {
    $alert-background: shift-color($value, $alert-bg-scale);
    $alert-border: shift-color($value, $alert-border-scale);
    $alert-color: shift-color($value, $alert-color-scale);

    @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
      $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
    }
    .alert-#{$state} {
      @include alert-variant($alert-background, $alert-border, $alert-color);
    }
  }

  // scss-docs-end alert-modifiers

  // helper
  // end of helper

  // scss-docs-start list-group-modifiers
  // List group contextual variants
  //
  // Add modifier classes to change text and background color on individual items.
  // Organizationally, this must come after the `:hover` states.

  @each $state, $value in $theme-colors {
    $list-group-variant-bg: shift-color($value, $list-group-item-bg-scale);
    $list-group-variant-color: shift-color($value, $list-group-item-color-scale);
    @if (contrast-ratio($list-group-variant-bg, $list-group-variant-color) < $min-contrast-ratio) {
      $list-group-variant-color: mix(
        $value,
        color-contrast($list-group-variant-bg),
        abs($list-group-item-color-scale)
      );
    }

    @include list-group-item-variant($state, $list-group-variant-bg, $list-group-variant-color);
  }
  // scss-docs-end list-group-modifiers

  // Table variants
  //
  // Table variants set the table cell backgrounds, border colors
  // and the colors of the striped, hovered & active tables

  // @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/maps';

  @import './utilities-dark.scss';
  // end of maps
  @import '~bootstrap/scss/tables';
  @import '~bootstrap/scss/forms';
  @import '~bootstrap/scss/nav';
  @import '~bootstrap/scss/dropdown';
  @import '~bootstrap/scss/modal';

  @import '~bootstrap/scss/helpers';
  @import '~bootstrap/scss/utilities/api';

}
