@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./OpenSans-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./OpenSans-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans-Medium.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./OpenSans-Medium.woff") format("woff"),
    /* Modern Browsers */ url("./OpenSans-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans-SemiBold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./OpenSans-SemiBold.woff") format("woff"),
    /* Modern Browsers */ url("./OpenSans-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./OpenSans-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./OpenSans-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
