@import "../../scss/utils/bootstrap.scss";

.Welcome_Popup {
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 0;
  .modal-content {
    margin: 0 auto;
    max-width: 900px;
    max-height: 500px;
    width: 100%;
    height: 500px;
  }
  .modal-header {
    display: none;
  }
  .welcome-right {
    div > div {
      background-repeat: no-repeat;
      background-size: contain;
      min-width: 100px;
      min-height: 100px;

      p {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 1024px) {
    .modal-content {
      max-width: 100%;
      max-height: unset;
      width: 96%;
      height: auto;
    }
  }
  @media (max-width: 575.98px) {
    .welcome-right {
      div > div {
        min-width: 90px;
        min-height: 90px;
      }
    }
  }
}
.welcome_step {
  position: relative;
  > div:first-child {
    background-image: url("./Step\ 1.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }
  > div:nth-child(2) {
    background-image: url("./Step\ 2.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }
  > div:nth-child(3) {
    background-image: url("./Step\ 3.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }
  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 80%;
    max-width: 300px;
    height: 42px;
    background-image: url("./Path\ 575.svg");
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: cover;
  }
}
