@import '../../scss/utils/bootstrap.scss';

.item_menu {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  .link_menu {
    transition: all 0.3s ease;
    white-space: nowrap;
    border-color: transparent;
    &:focus {
      outline: 0;
    }
    &.active {
      background-color: $blue;
      i,
      span {
        font-weight: 600 !important;
      }
    }
    &:hover {
      background-color: $blue;
      color: $white !important;

      i,
      span {
        color: $white !important;
        // font-weight: 600 !important;
      }
    }
    &.active {
      .icon.arrow {
        transform: rotate(90deg);
      }
    }

    i,
    span {
      transition: all 0.3s ease;
    }

    .icon {
      width: 24px;
      height: 24px;
      background-color: var(--sidebar-menu-item-color);
      &.arrow {
        width: 16px;
        height: 17px;
      }
    }
  }
}
[aria-controls='wr_list_submenu'] {
  &.active {
    .icon {
      transform: rotate(90deg);
    }
  }
}
#wr_list_submenu {
  background-color: var(--sidebar-submenu-bg);
  .item_menu {
    margin-bottom: 8px;
    &:first-child {
      padding-top: 8px;
    }
    &:last-child {
      padding-bottom: 8px;
      margin-bottom: 0;
    }
  }
}
.section_menu_title,
.header_logo {
  transition: all 0.3s ease;
}
.show {
  .btn-success.dropdown-toggle {
    &:focus {
      box-shadow: none;
    }
  }
}

.sidebar {
  .menu_title {
    padding: 0 24px;
  }
  .data-stream {
    background-color: var(--sidebar-submenu-bg);
    &-text {
      color: $dark-blue;
    }
    .link_menu {
      padding: 0 24px;
      .data-stream-text {
        font-size: $font-size-sm;
      }
      .data-stream-value {
        font-size: $h5-font-size;
      }
      &.active,
      &:hover {
        .data-stream-text {
          color: $white;
        }
      }
    }
  }
  .icon-submenu {
    display: none;
  }
  .button-language {
    padding-right: 24px;
  }
}
